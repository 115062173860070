import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const HistoryPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>History</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<p>Founded in 1976 by Millard and Linda Fuller, Habitat for Humanity International is a nonprofit, ecumenical Christian housing ministry dedicated to eliminating substandard housing and homelessness worldwide and to making adequate, affordable shelter a matter of conscience and action. Habitat invites people from all faiths and walks of life to work together in partnership, building houses with families in need.</p>
					<p>Habitat for Humanity operates around the globe and has helped build, renovate and repair more than 600,000 decent, affordable houses sheltering more than 3 million people worldwide.</p>
					<p>Habitat for Humanity Northwoods Wisconsin was founded in 1996 and finished its first Habitat home the following year.  We have built over 25 homes in our service area of Oneida and Vilas counties. Like all Habitat for Humanity affiliates, we rely on donations and volunteer help to fulfill our mission. In addition, we have a successful Habitat Restore operation on Lincoln Street in Rhinelander which helps fund our construction projects.</p>
					<p>We collaborate with the Rhinelander High School building trades class to help with the construction of Habitat homes in the Rhinelander area. We hope to expand our collaboration efforts with other area schools, churches, and businesses for new home construction throughout our two-county service area.</p>
					<StaticImage src="../../images/everyone-image.png" alt="Everyone deserves a decent place to live" />
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default HistoryPage

export const Head = () => (
	<Seo
        title="History | Habitat For Humanity Northwoods Wisconsin"
    />
)
